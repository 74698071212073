import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  getUsers(state: DataStateChangeEventArgs): Observable<any> {  
    return this.http.post(`${environment.apiUrl}/users/getUsers`, state);  
  };

  getUser(id: number): Observable<any> {    
    let params  = new HttpParams().set('id', id);
    return this.http.get(`${environment.apiUrl}/users/getUser`, {params: params});  
  };

  saveUser(user: any) {    
    return this.http.post(`${environment.apiUrl}/users/saveUser`, user);
  };

  deleteUser(id: number) {    
    let params  = new HttpParams().set('id', id);
    return this.http.delete(`${environment.apiUrl}/users/deleteUser`, {params});
  };

  getUserWithRoles(id: number): any {    
    let params  = new HttpParams().set('id', id);
    return this.http.get(`${environment.apiUrl}/users/getUserWithRoles`, {params: params});  
  };

  saveUserWithRoles(user: any) {    
    return this.http.post(`${environment.apiUrl}/users/saveUserWithRoles`, user);
  };

  getUserRoles(userId: number): any {    
    let params  = new HttpParams().set('userId', userId);
    return this.http.get(`${environment.apiUrl}/users/getUserRoles`, {params: params});  
  };
}
