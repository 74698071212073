import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoipsettingsComponent } from './voipsettings/voipsettings.component';
import {StatusComponent  } from './status/status.component';
import { FormsModule } from '@angular/forms';
import { FilterPipe} from '@app/core/pipes/filter.pipe';
//import { TranslateModule } from '@ngx-translate/core';
import {SharedModule} from '@app/shared/shared.module';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { VoipComponent } from './voip/voip.component';




@NgModule({
  declarations: [
    VoipsettingsComponent,
    StatusComponent,
    FilterPipe,
    VoipComponent
  ],
  imports: [
    FormsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule
  ],
  exports:[VoipsettingsComponent,StatusComponent,VoipComponent]
})
export class OwifySettingsModule { }
