import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-voipsettings',
  templateUrl: './voipsettings.component.html',
  styleUrls: ['./voipsettings.component.scss']
})
export class VoipsettingsComponent implements OnInit {

  devices: Array<any>
  selectedOutputDevice:string;
  selectedRingerDevice:string;
  selectedRingTone:string;
  muteChecked:boolean;
  devicesSubscription:Subscription;

  constructor() { 
    this.muteChecked = localStorage.getItem("MUTERING")=="true";
    this.selectedOutputDevice = localStorage.getItem("OUTPUTDEVICE");
    this.selectedRingerDevice = localStorage.getItem("RINGERDEVICE");
    this.selectedRingTone = localStorage.getItem("RINGERTONE");
  }
  onChange(event:any, source:string)
  {
    localStorage.setItem(source, event);
  }
  ngOnInit(): void {
    
  }
  ngOnDestroy(): void{
    if (this.devicesSubscription!=null)
       this.devicesSubscription.unsubscribe();
  }
}
