import { AuthenticationService } from '@app/core/services/authentication.service';
import { EventType } from '../services/event.enums';
import { EventService } from '../services/event.service';
import { SignalrService } from '../services/signalr.service';

export function appInitializer(authenticationService: AuthenticationService, eventService: EventService, signalrService:SignalrService) {
    return () => new Promise<void>(resolve => {
        //signalrService.initialize();
        if (window.location.pathname=="/account/login")
         {
             resolve();
         }
        else{
            // attempt to refresh token on app start up to auto authenticate
            authenticationService.refreshToken()
            .pipe((user)=>{ //ko se osveži token, gremo iskat še menije
                authenticationService.getMenus();/*.subscribe((menu)=>{
                    eventService.broadcast(EventType.MenuRetrieved,menu);
                })*/
                //štartajmo še signlar
                signalrService.startConnection();
                return user;
            })
            .subscribe()
            .add(resolve);
        }
        
    });
}