import { Injectable, OnInit,OnDestroy, Component } from '@angular/core';
import { AppInjector } from './app-injector.service';
import { EventService,EventCallback } from '@app/core/services/event.service';
import { EventType } from '@app/core/services/event.enums';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export class BaseComponent {
    public subscriptions: Array<Subscription> = [];
    protected eventService:EventService;
    protected translateService: TranslateService;
    constructor ()
    {
        this.eventService = AppInjector.injector.get(EventService);
        this.translateService = AppInjector.injector.get(TranslateService);
    }
    public SetPageTitle (title:string) {
        this.translateService.get(title).subscribe((tr)=>{
            this.eventService.broadcast(EventType.SetPageTitle,tr);
        });
    }
    // public subscribe (event:EventType, callback:EventCallback)
    // {
    //     this.eventService.subscribe(event,callback);
    // }
    // public unsubscribe(){
    //     this.subscriptions.forEach((sub)=>{
    //         if (!sub.closed)
    //             sub.unsubscribe();
    //     });
    // }
}
