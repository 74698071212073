import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication.service';

@Component({
  selector: 'app-voip',
  templateUrl: './voip.component.html',
  styleUrls: ['./voip.component.scss']
})
export class VoipComponent implements OnInit {

  @ViewChild('owifyVideo') owifyVideo:ElementRef;
  constructor(private authenticationService:AuthenticationService) { }

  ngOnInit(): void {
    //logirajmo se
    if (this.authenticationService.currentUserValue!=null)
    {
      if (this.authenticationService.currentUserValue.extensionVoip !== undefined)
      {
      }
    }
  }
  ngAfterViewInit(){
  }
}
