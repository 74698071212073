import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { Page404Component } from './extrapages/page404/page404.component';

const routes: Routes = [
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  // tslint:disable-next-line: max-line-length
  { path: '', component: LayoutComponent, 
    children:[
      { path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard]},
      { path: 'test', loadChildren: () => import('./modules/test/test.module').then(m => m.TestModule), canActivate: [AuthGuard] },
      { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard]  },
      { path: 'companies', loadChildren: () => import('./modules/companies/companies.module').then(m => m.CompaniesModule), canActivate: [AuthGuard]  },
      { path: 'users', loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard]  },
      { path: 'customers', loadChildren: () => import('./modules/customers/customers.module').then(m => m.CustomersModule), canActivate: [AuthGuard]  },
      { path: 'products', loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule), canActivate: [AuthGuard]  },
      { path: 'offers', loadChildren: () => import('./modules/offers/offers.module').then(m => m.OffersModule), canActivate: [AuthGuard]  },
      { path: 'code-lists', loadChildren: () => import('./modules/code-lists/code-lists.module').then(m => m.CodeListsModule), canActivate: [AuthGuard]  }
    ]
    //loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] 
  },
  { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule), canActivate: [AuthGuard] },
  // { path: 'test',component: LayoutComponent, loadChildren: () => import('./modules/test/test.module').then(m => m.TestModule), canActivate: [AuthGuard] },
  // { path: 'dashboard',component: LayoutComponent, loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy'/*,  enableTracing: true */ })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
