import { Component, OnInit, Input } from '@angular/core';
import { EventService } from '@app/core/services/event.service';
import { EventType } from '@app/core/services/event.enums';

@Component({
  selector: 'app-page-title',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})
export class PagetitleComponent implements OnInit {

  breadcrumbItems;
  title: string;
  titleSubscription:any;

  constructor(private eventService: EventService) {
    //subscribe v konstruktorju, da bo view pobral. Na ngOnInit je prepozno, ne osveži, ker ni v NgZoni
    this.titleSubscription = this.eventService.subscribe(EventType.SetPageTitle, (title) => {
      this.title=title;
    });
   }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.titleSubscription.unsubscribe()
  }

}
