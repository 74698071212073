import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../services/users.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  user: any = {};

  @Input() editId: number | 0;
  @Input() isProfileView: boolean | true;
  constructor(private usersService:UsersService, public activeModal:NgbActiveModal, private translateService:TranslateService, private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    if(this.isProfileView && this.editId>0)
    {
      this.usersService.getUser(this.editId).subscribe((data: any) => {        
        this.user = data;
      });
    }
    else {    
      if (this.editId>0)
      {
        this.usersService.getUserWithRoles(this.editId).subscribe((data: any) => {        
          this.user = data;
        });
      }  
      else
      {
        this.user.id=0;
        this.user.password_hash='';
        this.usersService.getUserRoles(this.editId).subscribe((data: any) => {        
          this.user.roles = data;
        });
      }
    }
  }

  
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  saveUser ()
  {
    if(this.isProfileView)
    {
      this.usersService.saveUser(this.user).subscribe((res: number) => {
        if(res > 0){
          Swal.fire({
            icon: 'success',
            title: this.translateService.instant('COMMON.Your_work_has_been_saved'),
            showConfirmButton: false,
            timer: 1500
          });

          this.activeModal.close('saved');
        }
        else{
          Swal.fire({
            icon: 'warning',
            title: 'Your work has not been saved',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }, err => {
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            showConfirmButton: false,
            timer: 1500
          });

        });
    }
    else {
      this.usersService.saveUserWithRoles(this.user).subscribe((res: number) => {
        if(res > 0){
          Swal.fire({
            icon: 'success',
            title: this.translateService.instant('COMMON.Your_work_has_been_saved'),
            showConfirmButton: false,
            timer: 1500
          });

          this.activeModal.close('saved');
        }
        else{
          Swal.fire({
            icon: 'warning',
            title: 'Your work has not been saved',
            showConfirmButton: false,
            timer: 1500
          });
        }
      }, err => {
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            showConfirmButton: false,
            timer: 1500
          });

        });
    }
  }

  deleteUser ()
  {
    Swal.fire({
      title: this.translateService.instant('COMMON.ARE_YOU_SURE'),
      text: this.translateService.instant('COMMON.YOU_WONT_BE_ABLE_TO_REVERT_THIS'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f46a6a',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translateService.instant('COMMON.DELETE'),
      cancelButtonText: this.translateService.instant('COMMON.CANCEL')
    }).then((result) => {
      if (result.isConfirmed) {
        this.usersService.deleteUser(this.user.id).subscribe((res: number) => {
          if(res > 0){
            Swal.fire({
              icon: 'success',
              title: 'COMMON.DELETED',
              showConfirmButton: false,
              timer: 1500
            });
            
            this.activeModal.close('success');
          }
        }, err => {
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              showConfirmButton: false,
              timer: 1500
            });
    
          });
      }
    });
  }

}
