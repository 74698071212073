import { Component, OnInit,OnDestroy } from '@angular/core';
import { EventType } from '@app/core/services/event.enums';
import { EventService } from '@app/core/services/event.service';
import { BaseComponent } from '@app/core/services/base.component';
import { BehaviorSubject } from 'rxjs';
import { SignalrService } from '@app/core/services/signalr.service';

@Component({
  selector: 'app-owify-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent extends BaseComponent implements OnInit, OnDestroy {

  signalrConnected = new BehaviorSubject<number>(-1);
  voipConnected = new BehaviorSubject<number>(-1);

  constructor(private signalrService:SignalrService) { 
    super();
    this.signalrConnected = this.signalrService.connectionStatus;
  }

  ngOnInit(): void {
    //subscribe on signalr events
    //this.subscriptions.push(this.signalrService.connectionStatus.subscribe((message)=>this.signalrConnected.next(1)));
    // this.subscribe(EventType.SignalrReconnected,(message)=>this.signalrConnected.next(1));
    // this.subscribe(EventType.SignalrReconnecting,(message)=>this.signalrConnected.next(0));
    // this.subscribe(EventType.SignalrClose,(message)=>this.signalrConnected.next(-1));

    // //read initial value as events are triggered on app initialize
    // this.signalrConnected.next(this.signalrService.isConnected()?1:0);

    // //subscribe on voip events
    
    // this.subscribe(EventType.VoipConnected,(message)=>this.signalrConnected.next(1));
    // this.subscribe(EventType.SignalrReconnected,(message)=>this.signalrConnected.next(1));
    // this.subscribe(EventType.SignalrReconnecting,(message)=>this.signalrConnected.next(0));
    // this.subscribe(EventType.SignalrClose,(message)=>this.signalrConnected.next(-1));

    // //read initial value as events are triggered on app initialize
    // this.signalrConnected.next(this.signalrService.isConnected()?1:0);
  }
  ngOnDestroy(): void {
      //this.unsubscribe();
  }

}
