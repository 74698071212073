import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';

import { User } from '../models/auth.models';
import { Router } from '@angular/router';
import { EventService } from './event.service';
import { EventType } from './event.enums';
import { MenuItem } from '../models/menu.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public menuItems:BehaviorSubject<Array<MenuItem>> = new BehaviorSubject<Array<MenuItem>>([]);

    constructor(private router: Router,private http: HttpClient,private eventService: EventService ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<User>(`${environment.apiUrl}/users/authenticate`, { username, password },{ withCredentials: true })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                    this.startRefreshTokenTimer();
                }
                //preberimo še menije
                this.getMenus();
                return user;
            }));
    }

    logout() {
        //revoke refresh token
        this.http.post<any>(`${environment.apiUrl}/users/revoke-token`, {}, { withCredentials: true }).subscribe();
        this.stopRefreshTokenTimer();
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.menuItems.next([]);
        this.eventService.broadcast(EventType.UserLogout,"");
        this.router.navigate(['/account/login']);
    }

    refreshToken()    {
        //let a = 5;
        //refresh-token
        return this.http.post<User>(`${environment.apiUrl}/users/refresh-token`, {}, { withCredentials: true })
        .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                this.startRefreshTokenTimer();
            }
            return user;
        }));
    }
    getMenus(){
        this.http.get<Array<MenuItem>>(`${environment.apiUrl}/system/getmenus`).subscribe((menu:Array<MenuItem>)=>{
            this.menuItems.next(menu);
        });
    }

    // helper methods
    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.currentUserValue.token.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}
