<div class="modal-header">
    <h4 class="modal-title">{{user.id>0 ? (user.username) : ('USER.NEW_USER' | translate)}}</h4>
    <button type="button" aria-hidden="true" class="btn-close" (click)="activeModal.dismiss('Cross click');"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-6 mb-3">
          <label class="form-label">{{'USER.FIRST_NAME' | translate}}</label>
          <input type="text" placeholder="{{'USER.FIRST_NAME' | translate}}" class="form-control" [(ngModel)]="user.first_name" #first_name="ngModel" [ngClass]="{'is-invalid': first_name.errors}" required>
      </div>
      <div class="col-sm-6 mb-3">
        <label class="form-label">{{'USER.LAST_NAME' | translate}}</label>
        <input type="text" placeholder="{{'USER.LAST_NAME' | translate}}" class="form-control" [(ngModel)]="user.last_name">
      </div>
      <div class="col-sm-6 mb-3">
        <label class="form-label">{{'USER.USERNAME' | translate}}</label>
        <input type="text" placeholder="{{'USER.USERNAME' | translate}}" class="form-control" [(ngModel)]="user.username" #username="ngModel" [ngClass]="{'is-invalid': username.errors}" required [disabled]="user.id>0">
      </div>
      <div class="col-sm-6 mb-3">
        <label class="form-label">{{'USER.PASSWORD' | translate}}</label>
        <input type="text" #password="ngModel" placeholder="{{'USER.PASSWORD_MIN_8_CHARACTERS' | translate}}" class="form-control"  [ngClass]="{'is-invalid': password.errors?.pattern}" [(ngModel)]="user.password_hash" pattern="^.{8,}$">
        <div *ngIf="password.errors?.pattern" class="invalid-tooltip">
          <span >{{'USER.PASSWORD_MIN_8_CHARACTERS2' | translate}}</span>
        </div>
      </div>
      <div class="col-sm-6 mb-3">
        <label class="form-label">{{'USER.EMAIL' | translate}}</label>
        <input type="text" placeholder="{{'USER.EMAIL' | translate}}" class="form-control" [(ngModel)]="user.email">
    </div>
    <div class="col-sm-6 mb-3">
      <label class="form-label">{{'USER.PHONENUMBER' | translate}}</label>
      <input type="text" placeholder="{{'USER.PHONENUMBER' | translate}}" class="form-control" [(ngModel)]="user.phoneNumber">
    </div>
    </div>  
    <div class="row" *ngIf="!isProfileView && user.roles?.length > 0">
      <hr>
      <div class="col-sm-12">
        <label class="form-label">{{'COMMON.ROLES' | translate}}</label>
      </div>
      <div class="col-sm-12 mb-3" *ngFor="let role of user.roles; let i = index">
        <div class="form-check form-checkbox-outline form-check-success mb-0">
          <input type="checkbox" id="{{'role' + i}}" class="form-check-input" [(ngModel)]="role.active">
          <label for="{{'role' + i}}" class="form-check-label">{{role.name}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">    
      <button type="button" class="btn btn-danger mr-2" (click)="deleteUser()" *ngIf="!isProfileView && user.id>0">{{'COMMON.DELETE' | translate}}</button>
      <button type="button" class="btn btn-dark" (click)="saveUser()" [disabled]="username.errors || first_name.errors || password.errors || (user.id==0 && user.password_hash=='')">{{'COMMON.SAVE' | translate}}</button>  
  </div>