import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"; 
import {environment} from "../../../environments/environment";
import { AuthenticationService } from '../services/authentication.service';
import { EventService } from './event.service';
import { EventType } from './event.enums';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  
private hubConnection: signalR.HubConnection
  eventUserLogout:Subscription;
  eventPageChanged: Subscription;
  public connectionStatus = new BehaviorSubject<number>(-1);

  constructor (private authenticationService: AuthenticationService, private eventService: EventService)
  {
    this.eventUserLogout= this.eventService.subscribe(EventType.UserLogout,()=>
        {
          this.disconnect();
        }
      );
    this.eventPageChanged= this.eventService.subscribe(EventType.SetPageTitle,()=>
      {
        this.SendMessage(EventType.SetPageTitle.toString(), window.location.pathname);
      }
    );
  }

  public startConnection = () => {
    return new Promise((resolve, reject) => {
    if (this.hubConnection!==undefined && this.hubConnection.state==signalR.HubConnectionState.Connected)
        resolve;
    else{
      this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl( environment.apiUrl + '/owifyHub',{ accessTokenFactory: () => 
        {
          return this.authenticationService.currentUserValue.token; 
        }})
      .withAutomaticReconnect([1000, 2000, 5000, 5000, 10000, 10000, 10000, 20000, 30000])
      .build();
      
      this.hubConnection
      .start()
      .then(() => {
          this.eventService.broadcast (EventType.SignalrConnected,"");
          this.SendMessage(EventType.SetPageTitle.toString(),window.location.pathname);
          this.eventService.broadcast(EventType.SignalrMessage,"");
          this.connectionStatus.next(1);
          console.log('Connection started');
          
          this.hubConnection.on('ReceiveMessage', (user, message?:string) => {
              this.eventService.broadcast(EventType.SignalrMessage,message);
              console.log(user + " : " + message);
          });

          this.hubConnection.onreconnecting((error?: Error)=>{
            this.eventService.broadcast (EventType.SignalrReconnecting,"");
            this.connectionStatus.next(0);
          });
      
          this.hubConnection.onreconnected((connectionId?: string)=>{
            this.eventService.broadcast (EventType.SignalrReconnected,"");
            this.connectionStatus.next(1);
            this.SendMessage(EventType.SetPageTitle.toString(),window.location.pathname);
          });
      
          this.hubConnection.onclose((error?: Error)=>{
            this.eventService.broadcast (EventType.SignalrClose,"");
            this.connectionStatus.next(-1);
          });
      })
      .catch((err) => {
        console.log('Error while starting connection: ' + err);
        console.log('reconnecting...');
        setTimeout(this.startConnection, 5000);
        this.eventService.broadcast (EventType.SignalrReconnecting,"");
        this.connectionStatus.next(0);
      } );
    }
    
    });
  }
  
  public disconnect (){
    if (this.hubConnection!=undefined || this.hubConnection.state==signalR.HubConnectionState.Connected)
      this.hubConnection.stop();
  }

  public async GetHubConnection() //:signalR.HubConnection
  {
    
      if (this.hubConnection!=undefined && this.hubConnection.state==signalR.HubConnectionState.Connected)
        return this.hubConnection;
      else
        await this.startConnection();
      return this.hubConnection;
  }

  public async SendMessage (method:string, message:any)
  {
    //zaenkrat poenostavimo. Pokličimo getconnection, ki bo ustvarilo connection, če ni. Bolj optimistično je, da predvidevamo, da bo signalr povezan
    return (await this.GetHubConnection()).send("SendMessage",method, message);
  }
  public isConnected()
  {
    return  (this.hubConnection!=undefined && this.hubConnection.state==signalR.HubConnectionState.Connected);
  }
  ngOnDestroy() {
    console.log('ngOnDestroy: cleaning up...');
    this.disconnect();
    this.eventPageChanged.unsubscribe();
    this.eventUserLogout.unsubscribe();
  }
}