import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { SimplebarAngularModule } from 'simplebar-angular';
// import { UIModule } from './ui/ui.module';

// import { WidgetModule } from './widget/widget.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule,
    NgbNavModule,
    SimplebarAngularModule
    /*,
    UIModule,
    WidgetModule*/
  ],
  exports:[
    TranslateModule,
    CommonModule,
    NgbNavModule,
    SimplebarAngularModule
  ]
})

export class SharedModule { }
