export class Enums{
  
}
export enum EventType {
    ChangeTopbar = 'changeTopbar',
    ChangeLayout = 'changeLayout',
    ChangeWidth = 'changeWidth',
    ChangeSidebartype = 'changeSidebartype',
    SetPageTitle = 'setPageTitle',
    SignalrConnected = "signalrConnected",
    SignalrReconnected = "signalrReconnected",
    SignalrReconnecting = "signalrReconnecting",
    SignalrClose = "signalrClosed",
    SignalrMessage = "signalrMessage",
    VoipConnected = "voipConnected",
    VoipDisconnected = "voipDisconnected",
    VoipError = "voipError",
    UserLogout = "userLogout",
    UserLogin = "userLogin",
    MenuRetrieved = "menuRetrieved"
  }