<div class="dropdown d-inline-block" ngbDropdown>
  <button
    type="button"
    class="btn header-item noti-icon"
    title="Voip settings"
    id="page-header-notifications-dropdown"
    ngbDropdownToggle
  >
    <i class="bx bx-cog bx-cog"></i>
  </button>
  <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
    <div class="card-body">
      <h4 class="card-title pb-3">Voip settings</h4>
      <div class="flexrow">
        <div>
          <label class="form-label">{{
            "VOIPSETTINGS.RINGERTONE.TEXT" | translate
          }}</label>
        </div>
        <div class="">
          <select
            aria-label="devices"
            [(ngModel)]="selectedRingTone"
            (ngModelChange)="onChange($event, 'RINGERTONE')"
            class="form-select"
          >
            <option value="ringer1">Ringer 1</option>
            <option value="ringer2">Ringer 2</option>
            <option value="ringer3">Ringer 3</option>
            <option value="ringer4">Ringer 4</option>
            <option value="ringer5">Ringer 5</option>
          </select>
        </div>
      </div>

      <div class="flexrow">
        <div>
          <label class="form-label">{{
            "VOIPSETTINGS.RINGERDEVICE.TEXT" | translate
          }}</label>
        </div>
        <div>
          <select
            aria-label="devices"
            [(ngModel)]="selectedRingerDevice"
            (ngModelChange)="onChange($event, 'RINGERDEVICE')"
            class="form-select"
          >
            <option
              *ngFor="let device of devices | filter: 'kind':'audiooutput'"
              [value]="device.value"
            >
              {{ device.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="flexrow">
        <div>
          <label class="form-label">{{
            "VOIPSETTINGS.OUTPUTDEVICE.TEXT" | translate
          }}</label>
        </div>
        <div>
          <select
            aria-label="devices"
            [(ngModel)]="selectedOutputDevice"
            (ngModelChange)="onChange($event, 'OUTPUTDEVICE')"
            class="form-select"
          >
            <option
              *ngFor="let device of devices | filter: 'kind':'audiooutput'"
              [value]="device.value"
            >
              {{ device.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="flexrow">
        <div>
          <label class="form-label">{{
            "VOIPSETTINGS.MUTERING.TEXT" | translate
          }}</label>
        </div>
        <div>
          <input
            aria-label="devices"
            type="checkbox"
            [(ngModel)]="muteChecked"
            (ngModelChange)="onChange($event, 'MUTERING')"
          />
        </div>
      </div>
    </div>
  </div>
</div>
